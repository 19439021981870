import { Injectable } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { GetOperationsCustomerProfileResp } from '@xpo-ltl/sdk-cityoperations';
import {
  ActionCd,
  AdvanceBeyondTypeCd,
  BillClassCd,
  BillStatusCd,
  BolPartyTypeCd,
  ChargeToCd,
  CommodityClassCd,
  LateTenderCd,
  MatchedPartySourceCd,
  MatchedPartyStatusCd,
  MiscLineItemCd,
  ShipmentActionCd,
  ShipmentRemarkTypeCd,
} from '@xpo-ltl/sdk-common';
import { ListCustCommonBolsResp, ListCustCommonBolsRqst } from '@xpo-ltl/sdk-customer';
import {
  AdvanceBeyondCarrier,
  AsEnteredBolParty,
  CustomsBond,
  GetShipmentResp,
  GetShipmentFromDb2Resp,
  MiscLineItem,
  Remark,
  UpsertShipmentRqst,
  ValidateParentChildShipmentResp,
} from '@xpo-ltl/sdk-shipment';
import { AccessorialFetchAddModel } from './../../bill-entry/model/accessorial-fetch-add-model';

import { BehaviorSubject, interval, Observable, of, Subject, throwError, merge } from 'rxjs';
import { filter, map, sample, take, skipWhile, switchMap, tap, catchError, distinctUntilChanged } from 'rxjs/operators';

import {
  cloneDeep as _cloneDeep,
  some as _some,
  find as _find,
  isEmpty as _isEmpty,
  isNull as _isNull,
  get as _get,
} from 'lodash';
import { XpoFormPanelService } from '../../../shared/form-components/form-panel/form-panel.service';
import { DateTimeReviver } from '../../../shared/json-helper';
import { CommodityPackageCdPipe } from '../../../shared/pipes/commodity-package-cd.pipe';
import { XpoPhoneNumberPipe } from '../../../shared/pipes/phone-number.pipe';
import { BolAccessorialDetail } from '../../../shared/services/bol-api/model/bol-accessorial-detail';
import { ConditioningService } from '../../../shared/services/conditioning-service/conditioning.service';
import { CustomerApiService } from '../../../shared/services/customer-api/customer-api-service';
import { DataOptions } from '../../../shared/services/data-api/data-options';
import { ErrorMessageParser } from '../../../shared/services/data-api/error-message-parser';
import { FormatValidationService } from '../../../shared/services/format-validation/format-validation.service';
import { LoggingConstants } from '../../../shared/services/logging-api/logging-constants';
import { NotificationService } from '../../../shared/services/notification/notification.service';
import { ParentShipmentValidationType } from '../../../shared/services/shipment-api/parent-shipment-validation-type.enum';
import { BillEntryShipmentApiService } from '../../../shared/services/shipment-api/shipment-api.service';
import { UserApiService } from '../../../shared/services/user-api/user-api.service';
import {
  Accessorial,
  AdditionalInformation,
  Bill,
  Commodity,
  Country,
  Party,
  PARTY_TYPE_ID,
  SupplementalReferenceNumber,
} from '../../bill-entry/model';
import { AccessorialFetchRemoveModel } from '../../bill-entry/model/accessorial-fetch-remove-model';
import { AccessorialSelectionType } from '../../bill-entry/model/accessorial-selection-type';
import { Actionable } from '../../bill-entry/model/actionable';
import { AdditionalInformationFormFields } from '../../bill-entry/model/additional-information-form-fields.enum';
import { BillEntryConstants } from '../../bill-entry/model/bill-entry-constants';
import { BillFormFields } from '../../bill-entry/model/bill-form-fields.enum';
import { BillSource } from '../../bill-entry/model/bill-source.enum';
import { OrderDetailsFormFields } from '../../bill-entry/model/order-details-form-fields.enum';
import { OrderHeaderFormFields } from '../../bill-entry/model/order-header-form-fields.enum';
import { PartyFormFields } from '../../bill-entry/model/party-form-fields.enum';
import { RemarksFormFields } from '../../bill-entry/model/remarks-form-fields.enum';
import { SpecialInstructionsFormFields } from '../../bill-entry/model/special-instructions-form-fields.enum';
import { TimeDateCriticalFormFields } from '../../bill-entry/model/time-date-critical.enum';
import { LoggingConstants as LocalLoggingConstants } from '../models/logging-constants';
import { DateTimeHelper } from '../util/date-time-helper';
import { touchControlsWithErrors } from '../util/touch-controls-with-errors';
import { BillEntryTransformerService } from './transformers/bill-entry-transformer.service';
import { CommoditiesTransformerService } from './transformers/commodities-transformer.service';
import { AccessorialTransformer } from './transformers/components/accessorial-transformer';
import { AdditionalInformationTransformer } from './transformers/components/additional-information-transformer';
import { PartiesTransformer } from './transformers/components/parties-transformer';
import { SrnTransformer } from './transformers/components/srn-transformer';
import { TimeDateCriticalTransformer } from './transformers/components/time-date-criticial-transformer';
import { BillValidatorService } from './validators/bill-validator.service';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigManagerProperties } from '../../../core/model/config-manager-properties.enum';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { LoggingApiService } from '@xpo-ltl/sdk-logging';

@Injectable({ providedIn: 'root' })
export class BillEntryService {
  readonly invalidAppointmentSics: string[] = ['NPR', 'UHO', 'UAK'];
  readonly invalidFreezableSics: string[] = ['NPR', 'UHO', 'UAK'];
  private _origBill: Bill;
  private _startTime: number;
  public hazMatCapable: boolean;
  public isShipmentCapable: boolean;
  private readonly _bypassProString;
  currentUserId: string;
  skipDNCVerification: boolean;
  appointmentIndReqBlockedByCust: boolean = false;
  bolAccessorials: BolAccessorialDetail[] = [];
  // CMK data validator
  cmkDataValidator$ = new BehaviorSubject<boolean>(false);
  // cheking the lotus user(Customer 2.0)
  isLotusUser = atob(localStorage.getItem('bill-entry.IsLotusUser')) === 'Yes';

  private tryToAddAccessorialSubject: Subject<AccessorialFetchAddModel> = new Subject<AccessorialFetchAddModel>();
  readonly tryToAddAccessorial$: Observable<AccessorialFetchAddModel> = this.tryToAddAccessorialSubject.asObservable();

  private tryToRemoveAccessorialSubject: Subject<AccessorialFetchRemoveModel> = new Subject<
    AccessorialFetchRemoveModel
  >();
  readonly tryToRemoveAccessorial$: Observable<
    AccessorialFetchRemoveModel
  > = this.tryToRemoveAccessorialSubject.asObservable();

  private forceTdcHandlingErrorSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly forceTdcHandlingError$: Observable<boolean> = this.forceTdcHandlingErrorSubject.asObservable();

  private removeAccesorialSubject: Subject<Accessorial> = new Subject<Accessorial>();
  readonly removeAccesorial$: Observable<Accessorial> = this.removeAccesorialSubject.asObservable();

  private manualUnmatchSubject: BehaviorSubject<PARTY_TYPE_ID[]> = new BehaviorSubject<PARTY_TYPE_ID[]>([]);
  readonly manualUnmatch$: Observable<PARTY_TYPE_ID[]> = this.manualUnmatchSubject.asObservable();

  set removeAccesorial(accesorial: Accessorial) {
    this.removeAccesorialSubject.next(accesorial);
  }

  private billSubject: BehaviorSubject<Bill> = new BehaviorSubject<Bill>(undefined);
  readonly bill$: Observable<Bill> = this.billSubject.asObservable();

  get bill(): Bill {
    return this.billSubject.value;
  }

  set bill(value: Bill) {
    this.billSubject.next(value);
  }

  private billFormGroupSubject: BehaviorSubject<FormGroup> = new BehaviorSubject<FormGroup>(undefined);
  readonly billFormGroup$: Observable<FormGroup> = this.billFormGroupSubject.asObservable();

  get billFormGroup(): FormGroup {
    return this.billFormGroupSubject.value;
  }

  set billFormGroup(value: FormGroup) {
    this.billFormGroupSubject.next(value);
  }

  private customerBolSubject: BehaviorSubject<ListCustCommonBolsResp> = new BehaviorSubject<ListCustCommonBolsResp>(
    undefined
  );
  readonly customerBol$: Observable<ListCustCommonBolsResp> = this.customerBolSubject.asObservable();

  get customerBol(): ListCustCommonBolsResp {
    return this.customerBolSubject.value;
  }

  set customerBol(value: ListCustCommonBolsResp) {
    this.customerBolSubject.next(value);
  }

  private getShipmentRespSubject: BehaviorSubject<GetShipmentResp> = new BehaviorSubject<GetShipmentResp>(undefined);
  readonly getShipmentResp$: Observable<GetShipmentResp> = this.getShipmentRespSubject.asObservable();

  get getShipmentResp(): GetShipmentResp {
    return this.getShipmentRespSubject.value;
  }

  set getShipmentResp(value: GetShipmentResp) {
    this.getShipmentRespSubject.next(value);
  }

  private getShipmentFromDb2RespSubject: BehaviorSubject<GetShipmentFromDb2Resp> = new BehaviorSubject<
    GetShipmentFromDb2Resp
  >(undefined);
  readonly getShipmentFromDb2Resp$: Observable<
    GetShipmentFromDb2Resp
  > = this.getShipmentFromDb2RespSubject.asObservable();

  get getShipmentFromDb2Resp(): GetShipmentFromDb2Resp {
    return this.getShipmentFromDb2RespSubject.value;
  }

  set getShipmentFromDb2Resp(value: GetShipmentFromDb2Resp) {
    this.getShipmentFromDb2RespSubject.next(value);
  }

  private getOperationsCustomerProfileRespSubject: BehaviorSubject<
    GetOperationsCustomerProfileResp
  > = new BehaviorSubject<GetOperationsCustomerProfileResp>(undefined);
  readonly getOperationsCustomerProfileResp$: Observable<
    GetOperationsCustomerProfileResp
  > = this.getOperationsCustomerProfileRespSubject.asObservable();

  get getOperationsCustomerProfileResp(): GetOperationsCustomerProfileResp {
    return this.getOperationsCustomerProfileRespSubject.value;
  }

  set getOperationsCustomerProfileResp(value: GetOperationsCustomerProfileResp) {
    this.getOperationsCustomerProfileRespSubject.next(value);
  }

  private addParentCommoditiesSubject: Subject<Commodity[]> = new Subject<Commodity[]>();
  readonly addParentCommodities$: Observable<Commodity[]> = this.addParentCommoditiesSubject.asObservable();

  set addParentCommodities(value: Commodity[]) {
    this.addParentCommoditiesSubject.next(value);
  }

  private addParentSupplementalRefNumSubject: Subject<SupplementalReferenceNumber[]> = new Subject<
    SupplementalReferenceNumber[]
  >();
  readonly addParentSupplementalRefNum$: Observable<
    SupplementalReferenceNumber[]
  > = this.addParentSupplementalRefNumSubject.asObservable();

  set addParentSupplementalRefNum(value: SupplementalReferenceNumber[]) {
    this.addParentSupplementalRefNumSubject.next(value);
  }

  private addParentAccessorialsSubject: Subject<Accessorial[]> = new Subject<Accessorial[]>();
  readonly addParentAccessorials$: Observable<Accessorial[]> = this.addParentAccessorialsSubject.asObservable();

  set addParentAccessorials(value: Accessorial[]) {
    this.addParentAccessorialsSubject.next(value);
  }

  private rrsIndicatorSubject: Subject<boolean> = new Subject<boolean>();
  readonly rrsIndicator$: Observable<boolean> = this.rrsIndicatorSubject.asObservable();

  set rrsIndicator(value: boolean) {
    this.rrsIndicatorSubject.next(value);
  }

  private g12IndicatorSubject: Subject<boolean> = new Subject<boolean>();
  readonly g12Indicator$: Observable<boolean> = this.g12IndicatorSubject.asObservable();

  set g12Indicator(value: boolean) {
    this.g12IndicatorSubject.next(value);
  }

  // srnCorrectionCapable: boolean;
  public _srnCorrectionCapable = new BehaviorSubject<boolean>(false);
  srnCorrectionCapable$ = this._srnCorrectionCapable.asObservable();
  
  hasOrderEntryGeneralBillerRoles = false;

  get accessorials(): FormArray {
    return this.billFormGroup.get(BillFormFields.Accessorials) as FormArray;
  }

  get specialInstructions() {
    return this.billFormGroupSubject.getValue().get(BillFormFields.SpecialInstructions);
  }

  get timeDateCriticalForm(): FormGroup {
    return <FormGroup>this.billFormGroupSubject.getValue().get(BillFormFields.TimeDateCritical);
  }

  private showCustomsDocLinkSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly showCustomsDocLink$: Observable<boolean> = this.showCustomsDocLinkSubject.asObservable();

  matchedPartiesPostalCdsInvalid$ = new BehaviorSubject<boolean>(null);
  matchedPartiesPostalCdsInvalid = this.matchedPartiesPostalCdsInvalid$.asObservable();
  /**  NORM/PSEG/PTLT */
  static isRrsOrG12Bill(billClassCode) {
    return (
      !!billClassCode &&
      (billClassCode === BillClassCd.NORMAL_MVMT ||
        billClassCode === BillClassCd.PARTIAL_SEGMENT ||
        billClassCode === BillClassCd.SPLIT_SEGMENT)
    );
  }

  /** For some of non-revenue -MOVR, COBZ, GCBZ, EXPD SPEED-1639 */
  static isNonRevenueBillClass(billClassCode) {
    let nonRevenueBill = false;

    if (billClassCode) {
      nonRevenueBill =
        billClassCode === BillClassCd.ASTRAY_FRT_SEGMENT ||
        billClassCode === BillClassCd.CO_BUS_SHPMT ||
        billClassCode === BillClassCd.GENERAL_CLAIMS_BUS_SHPMT ||
        billClassCode === BillClassCd.EXPEDITE;
    }

    return nonRevenueBill;
  }

  /** For some of non-revenue -NORM, ACCO, PTLT*/
  static isRevenueBillClass(billClassCode) {
    let revenueBill = false;

    if (billClassCode) {
      revenueBill =
        billClassCode === BillClassCd.NORMAL_MVMT ||
        billClassCode === BillClassCd.ACCESSORIAL_ONLY ||
        billClassCode === BillClassCd.SPLIT_SEGMENT
    }

    return revenueBill;
  }

  /** For bills that need estimated delivery date calculation to be disabled -speed-2430*/
  static isEstimatedDeliveryDateDisabledBillClass(billClassCode): boolean {
    return !(
      billClassCode === BillClassCd.SPLIT_SEGMENT ||
      billClassCode === BillClassCd.NORMAL_MVMT ||
      billClassCode === BillClassCd.ACCESSORIAL_ONLY ||
      billClassCode === BillClassCd.PARTIAL_SEGMENT ||
      billClassCode === BillClassCd.ASTRAY_FRT_SEGMENT
    );
  }

  static isPSEGBillClass(BillClassCode): boolean {
    return !!BillClassCode && BillClassCode === BillClassCd.PARTIAL_SEGMENT;
  }

  static isAccessorialOnlyBillClass(BillClassCode): boolean {
    return !!BillClassCode && BillClassCode === BillClassCd.ACCESSORIAL_ONLY;
  }

  static processRemarkActions(newRemark: Remark, originalRemark: string, typeCode: ShipmentRemarkTypeCd): Remark {
    if (originalRemark && originalRemark.trim().length > 0) {
      if (!newRemark) {
        const aRemark: Remark = new Remark();
        aRemark.typeCd = typeCode;
        aRemark.remark = originalRemark;
        aRemark.listActionCd = ActionCd.DELETE;
        return aRemark;
      } else if (originalRemark === newRemark.remark) {
        newRemark.listActionCd = ActionCd.NO_ACTION;
      } else {
        newRemark.listActionCd = ActionCd.UPDATE;
      }
    }
    return undefined;
  }

  static processCarrierActions(
    newCarrier: AdvanceBeyondCarrier,
    additionalInfo: AdditionalInformation,
    typeCode: AdvanceBeyondTypeCd
  ): AdvanceBeyondCarrier {
    let advanceCarrierDelete;

    // deletes
    if (!newCarrier) {
      // check original
      if (typeCode === AdvanceBeyondTypeCd.BYD_CARR) {
        // process delete
        if (additionalInfo.beyondCarrierSCAC) {
          advanceCarrierDelete = new AdvanceBeyondCarrier();
          advanceCarrierDelete.typeCd = typeCode;
          advanceCarrierDelete.sequenceNbr = `${additionalInfo.beyondCarrierSequenceNbr}`;
          advanceCarrierDelete.listActionCd = ActionCd.DELETE;
        }
      }

      if (typeCode === AdvanceBeyondTypeCd.ADV_CARR) {
        // process delete
        if (additionalInfo.advancedCarrierSCAC) {
          advanceCarrierDelete = new AdvanceBeyondCarrier();
          advanceCarrierDelete.typeCd = typeCode;
          advanceCarrierDelete.sequenceNbr = `${additionalInfo.advancedCarrierSequenceNbr}`;
          advanceCarrierDelete.listActionCd = ActionCd.DELETE;
        }
      }
      return advanceCarrierDelete;
    }

    // no actions
    if (typeCode === AdvanceBeyondTypeCd.BYD_CARR) {
      if (additionalInfo.beyondCarrierSCAC) {
        if (
          additionalInfo.beyondCarrierSCAC === newCarrier.carrierScacCd &&
          additionalInfo.beyondCarrierTrackingNumber === newCarrier.carrierProNbr &&
          additionalInfo.beyondCarrierPickupDate.getTime() === newCarrier.carrierPickupDate.getTime()
        ) {
          newCarrier.listActionCd = ActionCd.NO_ACTION;
        }
      }
    }

    if (typeCode === AdvanceBeyondTypeCd.ADV_CARR) {
      if (additionalInfo.advancedCarrierSCAC) {
        if (
          additionalInfo.advancedCarrierSCAC === newCarrier.carrierScacCd &&
          additionalInfo.advancedCarrierTrackingNumber === newCarrier.carrierProNbr &&
          additionalInfo.advancedCarrierPickupDate.getTime() === newCarrier.carrierPickupDate.getTime()
        ) {
          newCarrier.listActionCd = ActionCd.NO_ACTION;
        }
      }
    }

    return advanceCarrierDelete;
  }

  static processMiscLineItemActions(
    newMiscLineItem: MiscLineItem,
    additionalInfo: AdditionalInformation,
    typeCode: MiscLineItemCd
  ): MiscLineItem {
    let miscLineItemDelete;

    // deletes
    if (!newMiscLineItem) {
      // check original
      if (typeCode === MiscLineItemCd.COD_AMT) {
        // process delete
        if (additionalInfo.codAmount) {
          miscLineItemDelete = new MiscLineItem();
          miscLineItemDelete.lineTypeCd = typeCode;
          miscLineItemDelete.sequenceNbr = `${additionalInfo.codSequenceNbr}`;
          miscLineItemDelete.listActionCd = ActionCd.DELETE;
        }
      }

      if (typeCode === MiscLineItemCd.CASH_PPD_LN) {
        // process delete
        if (additionalInfo.driverCash) {
          miscLineItemDelete = new MiscLineItem();
          miscLineItemDelete.lineTypeCd = typeCode;
          miscLineItemDelete.sequenceNbr = `${additionalInfo.driverCashSequenceNbr}`;
          miscLineItemDelete.listActionCd = ActionCd.DELETE;
        }
      }

      if (typeCode === MiscLineItemCd.PART_PPD_LN) {
        // process delete
        if (additionalInfo.partPrepaidAmount) {
          miscLineItemDelete = new MiscLineItem();
          miscLineItemDelete.lineTypeCd = typeCode;
          miscLineItemDelete.sequenceNbr = `${additionalInfo.partPrepaidSequenceNbr}`;
          miscLineItemDelete.listActionCd = ActionCd.DELETE;
        }
      }

      if (typeCode === MiscLineItemCd.PART_COLL_LN) {
        // process delete
        if (additionalInfo.partCollectAmount) {
          miscLineItemDelete = new MiscLineItem();
          miscLineItemDelete.lineTypeCd = typeCode;
          miscLineItemDelete.sequenceNbr = `${additionalInfo.partCollectSequenceNbr}`;
          miscLineItemDelete.listActionCd = ActionCd.DELETE;
        }
      }
      return miscLineItemDelete;
    }

    // no actions
    if (typeCode === MiscLineItemCd.COD_AMT) {
      if (additionalInfo.codAmount) {
        if (
          additionalInfo.codAmount === newMiscLineItem.amount &&
          additionalInfo.codPaymentTypeCode === newMiscLineItem.paymentMethodCd
        ) {
          newMiscLineItem.listActionCd = ActionCd.NO_ACTION;
        }
      }
    }

    if (typeCode === MiscLineItemCd.CASH_PPD_LN) {
      if (additionalInfo.driverCash) {
        if (
          additionalInfo.driverCash === newMiscLineItem.amount &&
          additionalInfo.checkNumber === newMiscLineItem.checkNbr
        ) {
          newMiscLineItem.listActionCd = ActionCd.NO_ACTION;
        }
      }
    }

    if (typeCode === MiscLineItemCd.PART_PPD_LN) {
      if (additionalInfo.partPrepaidAmount) {
        if (
          additionalInfo.partPrepaidAmount === newMiscLineItem.amount &&
          additionalInfo.partPrepaidRemarks === newMiscLineItem.description
        ) {
          newMiscLineItem.listActionCd = ActionCd.NO_ACTION;
        }
      }
    }

    if (typeCode === MiscLineItemCd.PART_COLL_LN) {
      if (additionalInfo.partCollectAmount) {
        if (
          additionalInfo.partCollectAmount === newMiscLineItem.amount &&
          additionalInfo.partCollectRemarks === newMiscLineItem.description
        ) {
          newMiscLineItem.listActionCd = ActionCd.NO_ACTION;
        }
      }
    }

    return miscLineItemDelete;
  }

  constructor(
    private shipmentService: BillEntryShipmentApiService,
    private customerApi: CustomerApiService,
    private formatter: ConditioningService,
    private snackBar: XpoSnackBar,
    private userService: UserApiService,
    private router: Router,
    private commodityPackageCdPipe: CommodityPackageCdPipe,
    private panelService: XpoFormPanelService,
    private loggingApiService: LoggingApiService,
    private configManager: ConfigManagerService,
    private billValidatorService: BillValidatorService,
    private notificationService: NotificationService,
    private billEntryTransformerService: BillEntryTransformerService,
    private formatValidationService: FormatValidationService,
    private commoditiesTransformerService: CommoditiesTransformerService,
    private xpoPhoneNumberPipe: XpoPhoneNumberPipe
  ) {
    const storageSampleRate = this.configManager.getSetting<number>(ConfigManagerProperties.billStorageInterval);
    // this.userService.getLoggedInUser().then((response) => {
    //   this.currentUserId = response.userId;
    // });
    if (this.configManager.getSetting(ConfigManagerProperties.billStorageEnabled)) {
      this.billFormGroupSubject.subscribe((formGroup) => {
        if (formGroup) {
          formGroup.valueChanges
            .pipe(sample(interval(storageSampleRate)), distinctUntilChanged())
            .subscribe(() => {
              this.saveBillToLocalStorage(formGroup.getRawValue());
            });
        }
      });
    }
    this._bypassProString = configManager.getSetting(ConfigManagerProperties.bypassProInputString);
  }
  
  manualUnmatch(partyId: PARTY_TYPE_ID) {
    this.manualUnmatchSubject.next([...this.manualUnmatchSubject.value, partyId]);
  }

  cleanManualUnmatch() {
    this.manualUnmatchSubject.next([]);
  }

  setCustomsDocLinkVisible(visible: boolean) {
    this.showCustomsDocLinkSubject.next(visible);
  }

  forceTdcHandlingError() {
    this.forceTdcHandlingErrorSubject.next(true);
  }

  tryToAddAccessorial(value: AccessorialFetchAddModel) {
    this.tryToAddAccessorialSubject.next(value);
  }

  tryToRemoveAccessorial(value: AccessorialFetchRemoveModel) {
    this.tryToRemoveAccessorialSubject.next(value);
  }

  appointmentIndReqAvailable$(): Observable<boolean> {
    if (this.billFormGroup) {
      const asEnteredParties = <FormArray>this.billFormGroup.get(BillFormFields.AsEnteredBolParties);
      const consigneeParty = asEnteredParties.at(PARTY_TYPE_ID.Consignee);

      return consigneeParty.get(PartyFormFields.SicCd).valueChanges.pipe(
        filter(() => !this.appointmentIndReqBlockedByCust),
        map((destinationSic: string) => !this.invalidAppointmentSics.some((value: string) => value === destinationSic))
      );
    } else {
      return of(false);
    }
  }

  invalidFreezableSicsAvailable$(): Observable<boolean> {
    if (this.billFormGroup) {
      const matchingPerformed$ = this.billFormGroup
        .get(BillFormFields.MatchingPerformed)
        .valueChanges.pipe(filter((value: boolean) => !value));

      return merge(
        matchingPerformed$,
        this.manualUnmatch$.pipe(
          filter((parties: PARTY_TYPE_ID[]) => parties.some((party) => party === PARTY_TYPE_ID.Consignee))
        )
      ).pipe(
        map(() => {
          const asEnteredParties = <FormArray>this.billFormGroup.get(BillFormFields.AsEnteredBolParties);
          const consigneeParty = asEnteredParties.at(PARTY_TYPE_ID.Consignee);
          return this.isFreezableSicValid(consigneeParty.get(PartyFormFields.SicCd).value);
        })
      );
    } else {
      return of(true);
    }
  }

  isFreezableSicValid(sic: string): boolean {
    return !this.invalidFreezableSics.some((value: string) => value === sic);
  }

  isPickupAfterRrsCutoff(): boolean {
    return (
      this.billFormGroupSubject.getValue().get([BillFormFields.OrderHeader, OrderHeaderFormFields.PickupDate]).value >=
      new Date(this.configManager.getSetting(ConfigManagerProperties.rrsPickupThreshold))
    );
  }

  isPickupAfterG12Cutoff(): boolean {
    return this.billFormGroupSubject.getValue().get([BillFormFields.OrderHeader, OrderHeaderFormFields.PickupDate])
      ? this.billFormGroupSubject.getValue().get([BillFormFields.OrderHeader, OrderHeaderFormFields.PickupDate])
        .value >= new Date(this.configManager.getSetting(ConfigManagerProperties.g12PickupThreshold))
      : false;
  }

  isG12EligibleByAccessorial(globalAccessorial: AccessorialSelectionType) {
    const rrsControl = this.specialInstructions.get(SpecialInstructionsFormFields.G12Ind);

    if (
      rrsControl &&
      !rrsControl.value &&
      ['PED', 'PEO'].some((accessorial) => accessorial === globalAccessorial.accessorialCd)
    ) {
      rrsControl.disable({ onlySelf: true, emitEvent: false });
      return false;
    } else {
      return true;
    }
  }

  isG12Eligible() {
    const freezableInd = this.getSpecialInstructionValue(SpecialInstructionsFormFields.FreezableInd);
    const hazmatInd = this.getSpecialInstructionValue(SpecialInstructionsFormFields.HazmatInd);
    const exclusiveInd = this.getSpecialInstructionValue(SpecialInstructionsFormFields.ExclusiveUseInd);
    const guaranteedInd = this.getSpecialInstructionValue(SpecialInstructionsFormFields.GuaranteedInd);
    const rrsInd = this.getSpecialInstructionValue(SpecialInstructionsFormFields.RapidRemoteServiceInd);
    const hasDLGAccessorial = this.getAccessorialIndex('DLG');
    const isPickupAfterG12Cutoff = this.isPickupAfterG12Cutoff();

    return !(
      !isPickupAfterG12Cutoff ||
      freezableInd === true ||
      hazmatInd === true ||
      exclusiveInd === true ||
      guaranteedInd === true ||
      rrsInd === true ||
      hasDLGAccessorial >= 0
    );
  }

  private getSpecialInstructionValue(specialServiceField: SpecialInstructionsFormFields): boolean {
    return this.billFormGroup.get([BillFormFields.SpecialInstructions, specialServiceField])
      ? this.billFormGroup.get([BillFormFields.SpecialInstructions, specialServiceField]).value
      : false;
  }

  private getAccessorialIndex(accessorialCd: string): number {
    return this.billFormGroup.get(BillFormFields.Accessorials).value.findIndex((accessorial: Accessorial) => {
      if (accessorial.accessorialCd) {
        let retValue = false;

        if (Array.isArray(accessorial.accessorialCd)) {
          const index = accessorial.accessorialCd.findIndex((cd) => cd.toUpperCase() === accessorialCd.toUpperCase());
          retValue = index >= 0;
        } else {
          retValue =
            accessorial.accessorialCd && accessorial.accessorialCd.toUpperCase() === accessorialCd.toUpperCase();
        }
        return retValue;
      }
    });
  }

  getBillFromLocalStorage(): Bill {
    if (this.configManager.getSetting(ConfigManagerProperties.billStorageEnabled)) {
      const localStorageString = localStorage.getItem(BillEntryConstants.LOCAL_STORAGE_KEY);
      if (localStorageString) {
        const storageObj = JSON.parse(localStorageString, (key, value) =>
          commodityClassCdReviver(key, DateTimeReviver(key, value))
        ) as { bill: Bill; origBill: Bill; startTime: number };

        const bill: Bill = storageObj.bill;
        const proNumber = bill.orderHeader && bill.orderHeader.proNumber ? bill.orderHeader.proNumber : 'N/A';
        bill.isFromCache = true;
        // this will fix the problem of formGroup not showing as readOnly on loading the bill (Billed PRO) from cache
        this.billSubject.next(bill);
        this._origBill = storageObj.origBill;
        this._startTime = storageObj.startTime;

        this.loggingApiService.info(
          `Refreshing data from local storage, PRO: ${proNumber}.  ${localStorageString}`,
          null,
          LocalLoggingConstants.ActionNames.CacheReload,
          LocalLoggingConstants.ActivityNames.CacheOperation
        );
        return bill;
      }
    }
    return undefined;
  }

  saveBillToLocalStorage(bill: Bill) {
    if (this.configManager.getSetting(ConfigManagerProperties.billStorageEnabled)) {
      // loosing billStatCd when saving the bill from billFormGroup
      // this will fix the problem of formGroup not showing as readOnly on loading the bill (Billed PRO) from cache
      if (this.bill && bill.orderHeader) {
        bill.orderHeader.billStatusCode = this.billSubject.value.orderHeader.billStatusCode;
      }

      if (!bill.billEntryUserId && this.bill && this.bill.billEntryUserId) {
        bill.billEntryUserId = this.bill.billEntryUserId;
      }

      localStorage.setItem(
        BillEntryConstants.LOCAL_STORAGE_KEY,
        JSON.stringify({
          bill: bill,
          origBill: this._origBill,
          startTime: this._startTime,
        })
      );
    }
  }

  deleteBillFromLocalStorage() {
    if (this.configManager.getSetting(ConfigManagerProperties.billStorageEnabled)) {
      localStorage.removeItem(BillEntryConstants.LOCAL_STORAGE_KEY);
    }
  }
  private logSessionGate(actionName: string, message: string) {
    this.loggingApiService.info(
      message,
      undefined,
      actionName,
      LocalLoggingConstants.ActivityNames.BillEntryNavigation,
      LocalLoggingConstants.TransactionType.SessionTimestamps
    );
  }

  startSession(bill: Bill): Bill {
    if (bill.isFromCache) {
      // re-hydrating from local cache
      this.loggingApiService.info('rehydrating from local cache');
      this.billSubject.next(bill);
    } else {
      this.logSessionGate(LocalLoggingConstants.ActionNames.BillEntryStart, 'Start Session');
      this._startTime = Date.now();
      this._origBill = _cloneDeep(bill);
      this.saveBillToLocalStorage(bill);
    }
    return bill;
  }

  endSession(bill: Bill): Bill {
    bill.sessionStartTime = this._startTime;
    bill.sessionEndTime = Date.now();
    // 15 character limit
    bill.billEntryUserId = this.currentUserId.slice(0, 14);
    return this.reconcileSupplementalReferenceNumberDiff(
      this.reconcileCommoditiesDiff(
        this.reconcileAsMatchedPartiesDiff(this.reconcileAsEnteredBolPartiesDiff(this.reconcileAccessorialsDiff(bill)))
      )
    );
  }

  submit(billFG: FormGroup): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let totalPiecesCount = 0;
      let totalCommodityWeight = 0;
      if (billFG.valid) {
        const bill = billFG.getRawValue();
        bill.orderHeader.proNumber = this.formatter.conditionProNumber(bill.orderHeader.proNumber, 11);

        if (!this.isShipmentCapable) {
          throw new Error('User does not have the required role to submit bills');
        }
        if (bill.orderDetails.commodities && bill.orderDetails.commodities.length > 0) {
          bill.orderDetails.commodities.forEach((commodity) => {
            // re-calculating the pieces count and weight to resolve the lcs-17819 bug
            totalPiecesCount += commodity.pieces;
            totalCommodityWeight += commodity.weight;
            commodity.packaging = this.commodityPackageCdPipe.untransform(commodity.packaging);
          });
        } else {
          this.loggingApiService.error(
            'Commoditites missing before reconciling differences!  Displaying error to user.'
          );
          if (this._origBill.orderDetails.commodities) {
            this.loggingApiService.info(
              `Original commodies payload: ${JSON.stringify(this._origBill.orderDetails.commodities)}`
            );
          }
          throw new Error('Commodity information missing, unable to continue processing.');
        }
        let rqst = this.billEntryTransformerService.toUpsertShipmentRqst(this.endSession(bill));
        this.logSessionGate(LocalLoggingConstants.ActionNames.BillEntrySubmit, 'Bill submitted');
        const elapsedTime = bill.sessionEndTime - bill.sessionStartTime;
        this.loggingApiService.logHttpStats(
          'Bill Entry Elapsed Time',
          '',
          0,
          '',
          '',
          elapsedTime,
          undefined,
          bill.orderHeader.proNumber
        );

        rqst = this.reconcileRemarksDiff(rqst);
        rqst = this.reconcileAdditionalInfoDiff(rqst);
        rqst.actionCd = ShipmentActionCd.SUBMIT;

        // run sanity checks to make sure everything has the best chance possible of working!
        const validationErrors = this.billValidatorService.validate(rqst);
        if (validationErrors && validationErrors.length > 0) {
          let errorMessage = `Unexpected Error${validationErrors.length > 1 ? 's' : ''}: `;
          validationErrors.forEach((ve) => (errorMessage += `${ve.errorMessage} - `));
          errorMessage = errorMessage.substring(0, errorMessage.length - 3);
          const logMessage =
            `${errorMessage} - Request: ${JSON.stringify(rqst)} ` +
            `Original Bill: ${JSON.stringify(this._origBill)} - Bill: ${JSON.stringify(bill)}`;
          this.loggingApiService.error(
            logMessage,
            null,
            LoggingConstants.ActionNames.Error,
            LoggingConstants.ActivityNames.BillSubmitUnexpectedError,
            LocalLoggingConstants.TransactionType.BillSubmit
          );
          this.snackBar.open({
            message: errorMessage,
            matConfig:{
              duration:10000,
              verticalPosition: 'bottom',
            },
            status:'error'
          })
          reject({ handled: true });
        } else {
          // Updating the request payload -> Pushing the APT accessorial if it is available in getShipment response 
          // For resolving APT accessorial issue LCS-17821
          if(this.getShipmentResp && this.getShipmentResp.accessorialService && this.getShipmentResp.accessorialService.length){
            let apt = this.getShipmentResp.accessorialService.find((ele) => ele.accessorialCd === 'APT');
            if(apt){
              rqst.accessorialService.push(this.createAptAccessorials(apt));
            }            
          }
          // validating SIC code -> It should be uppercase for all customers
          let shipmentData = rqst.shipment;
            shipmentData.originTerminalSicCd = shipmentData.originTerminalSicCd.toUpperCase();
            shipmentData.destinationTerminalSicCd = shipmentData.destinationTerminalSicCd.toUpperCase();
            // re-assigning the total pieces count and weight to resolve the lcs-17819 bug 
            shipmentData.totalPiecesCount = totalPiecesCount;
            shipmentData.totalWeightLbs = totalCommodityWeight;
            rqst.shipment = shipmentData;
          // validating ZIP code -> It should be uppercase for all customers
          rqst.asEnteredBolParty.forEach(ele => {
            if (ele.zip6) {
              ele.zip6 = ele.zip6.toUpperCase();
              // Please disable this(validateCountryAgain) functionality when Almex is going live 
              this.validateCountryAgain(ele);
            }
            if (ele.zip4RestUs) {
              ele.zip4RestUs = ele.zip4RestUs.toUpperCase();
            }
          });
         
          rqst.asMatchedParty.forEach(ele => {
            if (ele.zip6) {
              ele.zip6 = ele.zip6.toUpperCase();
              // Please disable this(validateCountryAgain) functionality when Almex is going live 
              this.validateCountryAgain(ele);
            }
            if (ele.zip4RestUs) {
              ele.zip4RestUs = ele.zip4RestUs.toUpperCase();
            }
          });

          // checking consignee is available or not in the asMatchedparty
          const consignee = rqst.asMatchedParty.find((ele: any) => ele.typeCd === 'Cons');
          const billTo = rqst.asMatchedParty.find(
            (ele: any) => ele.typeCd === 'BillToOtb' || ele.typeCd === 'BillToInb' || ele.typeCd === 'billto'
          );

          // Validating the BillTo and TDC to add RTLROLLOUT flag
          if (rqst.timeDateCritical) {
            rqst.timeDateCritical = this.validateRtlRollout(rqst);
          }

          if (!consignee) {
            // Set unmatched Consignee
            const party: any = Object.assign({}, rqst.asEnteredBolParty[PARTY_TYPE_ID.Consignee]);
            const typeCd = party.partyTypeCd;
            const phoneParts = PartiesTransformer.parsePhoneParts(party.phoneNbr);
            delete party.partyTypeCd;
            rqst.asMatchedParty[PARTY_TYPE_ID.Consignee] = party;
            rqst.asMatchedParty[PARTY_TYPE_ID.Consignee].sequenceNbr = '-1';
            rqst.asMatchedParty[PARTY_TYPE_ID.Consignee].matchedStatusCd = MatchedPartyStatusCd.UN_MCH;
            rqst.asMatchedParty[PARTY_TYPE_ID.Consignee].matchedSourceCd = MatchedPartySourceCd.NOT_MATCHED;
            rqst.asMatchedParty[PARTY_TYPE_ID.Consignee].cisCustNbr = null;
            rqst.asMatchedParty[PARTY_TYPE_ID.Consignee].asMatchedMadCd = '';
            rqst.asMatchedParty[PARTY_TYPE_ID.Consignee].typeCd = typeCd;
            rqst.asMatchedParty[PARTY_TYPE_ID.Consignee].phoneAreaCdNbr = phoneParts.areaCode;
            rqst.asMatchedParty[PARTY_TYPE_ID.Consignee].phoneNbr = phoneParts.phone;
            rqst.asMatchedParty[PARTY_TYPE_ID.Consignee].phoneExtensionNbr = phoneParts.extension || party.extension;
            if (billTo !== null && billTo) {
              // we are reassigning the billTo
              rqst.asMatchedParty[PARTY_TYPE_ID.BillTo] = billTo;
            }
            // we are checking billto if consignee is not available and this is derived through the consignee
            if (
              rqst.asMatchedParty[PARTY_TYPE_ID.BillTo] &&
              rqst.asEnteredBolParty[PARTY_TYPE_ID.BillTo] &&
              rqst.shipment.chargeToCd === 'Coll' &&
              rqst.asMatchedParty[PARTY_TYPE_ID.BillTo].matchedStatusCd === MatchedPartyStatusCd.DERIVED_BILL_TO
            ) {
              const party: any = Object.assign({}, rqst.asEnteredBolParty[PARTY_TYPE_ID.BillTo]);
              const typeCd = billTo.typeCd;
              const phoneParts = PartiesTransformer.parsePhoneParts(party.phoneNbr);
              delete party.partyTypeCd;
              rqst.asMatchedParty[PARTY_TYPE_ID.BillTo] = party;
              rqst.asMatchedParty[PARTY_TYPE_ID.BillTo].sequenceNbr = '-1';
              rqst.asMatchedParty[PARTY_TYPE_ID.BillTo].matchedStatusCd = MatchedPartyStatusCd.UN_MCH;
              rqst.asMatchedParty[PARTY_TYPE_ID.BillTo].matchedSourceCd = MatchedPartySourceCd.NOT_MATCHED;
              rqst.asMatchedParty[PARTY_TYPE_ID.BillTo].cisCustNbr = null;
              rqst.asMatchedParty[PARTY_TYPE_ID.BillTo].asMatchedMadCd = '';
              rqst.asMatchedParty[PARTY_TYPE_ID.BillTo].typeCd = typeCd;
              rqst.asMatchedParty[PARTY_TYPE_ID.BillTo].phoneAreaCdNbr = phoneParts.areaCode;
              rqst.asMatchedParty[PARTY_TYPE_ID.BillTo].phoneNbr = phoneParts.phone;
              rqst.asMatchedParty[PARTY_TYPE_ID.BillTo].phoneExtensionNbr = phoneParts.extension || party.extension;
            } else if (
              rqst.asMatchedParty[PARTY_TYPE_ID.BillTo] &&
              !rqst.asEnteredBolParty[PARTY_TYPE_ID.BillTo] &&
              rqst.shipment.chargeToCd === 'Coll' &&
              rqst.asMatchedParty[PARTY_TYPE_ID.BillTo].matchedStatusCd === MatchedPartyStatusCd.DERIVED_BILL_TO
            ) {
              if (PARTY_TYPE_ID.BillTo > -1) {
                // only splice array when item is found
                rqst.asMatchedParty.splice(PARTY_TYPE_ID.BillTo, 1);
              }
            }
          }

          // Validating listActionCd for resolving consignee zip error(LCS-16801)
          if (consignee && rqst.asMatchedParty[PARTY_TYPE_ID.Consignee].listActionCd === ActionCd.NO_ACTION) {
            if (rqst.asMatchedParty[PARTY_TYPE_ID.Consignee].shipmentInstId) {
              rqst.asMatchedParty[PARTY_TYPE_ID.Consignee].listActionCd = ActionCd.UPDATE
            } else {
              rqst.asMatchedParty[PARTY_TYPE_ID.Consignee].listActionCd = ActionCd.ADD
            }
          }

          // Validateing sequenceNbr and listActionCd
          this.validateSequenceNbr(rqst.asMatchedParty);

          // If Bill To info if Bill To is same as P&D then will not store billTo data in DB
          // Revalidating the billTo in terms of madcode and functionId
          if (
            rqst.asMatchedParty[PARTY_TYPE_ID.BillTo] &&
            rqst.asMatchedParty[PARTY_TYPE_ID.BillTo].cisCustNbr
          ) {
            this.customerApi.verifyCustomer(rqst.asMatchedParty[PARTY_TYPE_ID.BillTo].cisCustNbr).subscribe(
              (res: any) => {
                let billTo = null;
                if (res.data.customerLocation.customerLocationFunction.length) {
                  billTo = res.data.customerLocation.customerLocationFunction.filter(
                    (item) => item.customerLocationFuncId === rqst.asMatchedParty[PARTY_TYPE_ID.BillTo].cisCustNbr
                  );
                  if (billTo != null && billTo[0].functionCd === 'PickupOrDelivery') {
                    rqst.asMatchedParty.pop();
                  } else if (billTo != null && billTo[0].functionCd === 'Bill-To') {
                    rqst.asMatchedParty[PARTY_TYPE_ID.BillTo].asMatchedMadCd = billTo[0].madCd;
                  }
                } else {
                  Object.assign(
                    rqst.asMatchedParty[PARTY_TYPE_ID.BillTo],
                    rqst.asEnteredBolParty[PARTY_TYPE_ID.BillTo]
                  );
                  rqst.asMatchedParty[PARTY_TYPE_ID.BillTo].matchedStatusCd = MatchedPartyStatusCd.UN_MCH;
                  rqst.asMatchedParty[PARTY_TYPE_ID.BillTo].matchedSourceCd = MatchedPartySourceCd.NOT_MATCHED;
                  rqst.asMatchedParty[PARTY_TYPE_ID.BillTo].cisCustNbr = null;
                  rqst.asMatchedParty[PARTY_TYPE_ID.BillTo].asMatchedMadCd = '';
                  const party: any = rqst.asMatchedParty[PARTY_TYPE_ID.BillTo];
                  delete party.partyTypeCd;
                  rqst.asMatchedParty[PARTY_TYPE_ID.BillTo] = party;
                }

                // Adding extra logging to track the bill payload for any tibco suspend
                this.loggingApiService.info(
                  `Original bill payload: ${JSON.stringify(rqst)}`
                );
                this.shipmentService
                  .upsertShipment(rqst)
                  .pipe(take(1))
                  .subscribe(
                    () => {
                      resolve(true);
                      this._origBill = null;
                      this.billSubject.next(null);
                      this.getShipmentRespSubject.next(null);
                      this.deleteBillFromLocalStorage();
                    },
                    (err: Response) => {
                      reject(ErrorMessageParser.parseMessage(err));
                    }
                  );
              },
              (error) => {
                // Adding extra logging to track the bill payload for any tibco suspend
                this.loggingApiService.info(
                  `Original bill payload: ${JSON.stringify(rqst)}`
                );
                // If we got erros 400/500/401/404 etc then will hit the submit without validate the customer
                this.shipmentService
                  .upsertShipment(rqst)
                  .pipe(take(1))
                  .subscribe(
                    () => {
                      resolve(true);
                      this._origBill = null;
                      this.billSubject.next(null);
                      this.getShipmentRespSubject.next(null);
                      this.deleteBillFromLocalStorage();
                    },
                    (err: Response) => {
                      reject(ErrorMessageParser.parseMessage(err));
                    }
                  );
              }
            );
          } else {
            // Adding extra logging to track the bill payload for any tibco suspend
            this.loggingApiService.info(
              `Original bill payload: ${JSON.stringify(rqst)}`
            );
            this.shipmentService
              .upsertShipment(rqst)
              .pipe(take(1))
              .subscribe(
                () => {
                  resolve(true);
                  this._origBill = null;
                  this.billSubject.next(null);
                  this.getShipmentRespSubject.next(null);
                  this.deleteBillFromLocalStorage();
                },
                (err: Response) => {
                  reject(ErrorMessageParser.parseMessage(err));
                }
              );
          }
        }
      }
    });
  }

  // This is new enhancement related TDC(LCS-17002)
  validateRtlRollout(request) {
    const billTo = request.asMatchedParty[PARTY_TYPE_ID.BillTo];
    let tdc = request.timeDateCritical;
    tdc.deliveryServiceTypeCd = null;
    if (billTo) {
      let finalStr = billTo.name1.trimLeft().toUpperCase().split(' ')[0];
      if (finalStr === 'RTLROLLOUT') {
        tdc.deliveryServiceTypeCd = 'RETAIL';
      }
    }
    return tdc;
  }

  // Validate Country for each party LCS-17936
  // Please remove this functionality when Almex is going to live 
  validateCountryAgain(party){
    if (!party.zip6.length || this.formatValidationService.isValidUsZipCode(party.zip6)) {
      party.countryCd = Country.US.code
    } else if (
      party.zip6.length === 6 &&
      this.formatValidationService.isValidCanadianPostalCode(party.zip6)
    ) {
      party.countryCd = Country.CA.code
    }
  }

  submitSrnCorrections(billFG: FormGroup): Observable<boolean> {
    if (billFG.valid) {
      const bill: Bill = billFG.getRawValue();
      const rqst = this.billEntryTransformerService.toUpsertShipmentSuppRefNbrsRqst(bill);
      rqst.shipmentInstId = `${bill.orderHeader.shipmentInstanceId}`;
      return this.shipmentService.upsertShipmentSuppRefNbrs(rqst).pipe(
        switchMap(() => {
          const appointmentRequired = billFG
            .get(BillFormFields.TimeDateCritical)
            .get(TimeDateCriticalFormFields.AppointmentRequired);
          if (appointmentRequired && appointmentRequired.dirty) {
            const shipmentInstId = this.getShipmentFromDb2Resp.shipment.shipmentInstId;
            return this.shipmentService.updateAppointmentRequiredInd(shipmentInstId, appointmentRequired.value);
          } else {
            return of(undefined);
          }
        }),
        map(() => true),
        tap(() => {
          this.billSubject.next(undefined);
          this.getShipmentFromDb2RespSubject.next(undefined);
        }),
        catchError((err) => throwError(ErrorMessageParser.parseMessage(err)))
      );
    }
  }

  reviewSrnCorrections(billFG: FormGroup): Observable<boolean> {
    const updateFunc = (observer) => {
      if (billFG && billFG.valid) {
        if (this.panelService.panelHasWarnings) {
          this.snackBar.open({
            message:'Form Warning: Please review alerts before submitting.',
            matConfig:{
              duration: 5000,
              verticalPosition: 'bottom',
            },
            status:'warn'
          });
        }
        observer.next(true);
        observer.complete();
        return;
      } else {
        this.snackBar.open({
          message:'Form Error: Please review invalid fields.',
          matConfig:{
            duration: 5000,
            verticalPosition: 'bottom',
          },
          status:'error'
        });
        observer.next(false);
        observer.complete();
        return;
      }
    };
    return Observable.create((observer) => {
      // Touch feilds just in case one hasnt updated their value and validity, then check if bill is valid
      touchControlsWithErrors(billFG);
      if (billFG.status === 'PENDING') {
        billFG.statusChanges
          .pipe(
            skipWhile((v) => v === 'PENDING'),
            take(1)
          )
          .subscribe(() => {
            updateFunc(observer);
          });
      } else {
        updateFunc(observer);
      }
    });
  }

  review(billFG: FormGroup): Observable<boolean> {
    this.logSessionGate(LocalLoggingConstants.ActionNames.BillEntrySubmit, 'Review Started');
    const updateFunc = (observer) => {
      if (billFG && billFG.valid) {
        if (this.hasExceedLineItemLimit(99)) {
          this.snackBar.open({
            message:'Form Error: Cannot submit more than 99 total commodity and accessorial lines.',
            matConfig:{
              duration: 10000,
              verticalPosition: 'bottom',
            },
            status:'error'
          });
          observer.next(false);
          observer.complete();
          return;
        } else if (
          !this.hazMatCapable &&
          billFG.get([BillFormFields.SpecialInstructions, SpecialInstructionsFormFields.HazmatInd]).value
        ) {
          this.snackBar.open({
            message:'Roles Error: User cannot submit hazmat shipments.',
            matConfig:{
              duration: 10000,
              verticalPosition: 'bottom',
            },
            status:'error'
          });
          observer.next(false);
          observer.complete();
          return;
        } else if (this.panelService.panelHasWarnings) {
          this.snackBar.open({
            message:'Form Warning: Please review alerts before submitting.',
            matConfig:{
              duration: 5000,
              verticalPosition: 'bottom',
            },
            status:'warn'
          });
        }
        observer.next(true);
        observer.complete();
        return;
      } else {
        this.snackBar.open({
          message:'Form Error: Please review invalid fields.',
          matConfig:{
            duration: 5000,
            verticalPosition: 'bottom',
          },
          status:'error'
        });
        observer.next(false);
        observer.complete();
        return;
      }
    };
    return Observable.create((observer) => {
      // Touch feilds just in case one hasnt updated their value and validity, then check if bill is valid
      touchControlsWithErrors(billFG);
      if (billFG.status === 'PENDING') {
        billFG.statusChanges
          .pipe(
            skipWhile((v) => v === 'PENDING'),
            take(1)
          )
          .subscribe(() => {
            updateFunc(observer);
          });
      } else {
        updateFunc(observer);
      }
    });
  }

  save(bill: Bill) {
    bill.orderHeader.proNumber = this.formatter.conditionProNumber(bill.orderHeader.proNumber, 11);

    if (bill.orderDetails.commodities && bill.orderDetails.commodities.length > 0) {
      bill.orderDetails.commodities.forEach((commodity) => {
        commodity.packaging = this.commodityPackageCdPipe.untransform(commodity.packaging);
      });
    }

    let rqst = this.billEntryTransformerService.toUpsertShipmentRqst(this.endSession(bill));
    rqst = this.reconcileRemarksDiff(rqst);
    rqst = this.reconcileAdditionalInfoDiff(rqst);
    rqst.actionCd = ShipmentActionCd.SAVE;

    this.shipmentService
      .upsertShipment(rqst)
      .pipe(take(1))
      .subscribe(
        () => {
          this.snackBar.open({
            message:`Order for Pro Number ${this.formatter.conditionProNumber(bill.orderHeader.proNumber,10)} was submitted successfully.`,
            matConfig:{
              duration: 10000,
              verticalPosition: 'bottom',
            },
            status:'success'
          });
          this.router.navigate(['ebol-search']);
        },
        (error) => {
          this.snackBar.open({
            message:'error',
            matConfig:{
              duration: 10000,
              verticalPosition: 'bottom',
            },
            status:'error'
          });
        }
      );
  }

  getCustomerBol(
    chargeToCode: ChargeToCd,
    shipperCustomerNumber: number,
    consigneeCustomerNumber: number,
    billToCustomerNumber: number,
    pickUpDate: Date
  ) {
    const bolRequest = new ListCustCommonBolsRqst();

    if (billToCustomerNumber) {
      bolRequest.billToCustNbr = billToCustomerNumber;
    }
    if (consigneeCustomerNumber) {
      bolRequest.consigneeCustNbr = consigneeCustomerNumber;
    }
    if (shipperCustomerNumber) {
      bolRequest.shipperCustNbr = shipperCustomerNumber;
    }

    if (chargeToCode) {
      bolRequest.chargeToCd = chargeToCode;
    }
    if (pickUpDate) {
      bolRequest.effectiveDate = DateTimeHelper.conditionDateToServer(pickUpDate);
    }

    this.customerApi
      .getCustomerBol(bolRequest)
      .pipe(take(1))
      .subscribe((res) => {
        this.customerBolSubject.next(res);
      });
  }

  get bypassProString(): string {
    return this._bypassProString;
  }

  private reconcileAsEnteredBolPartiesDiff(bill: Bill): Bill {
    if (this._origBill !== undefined && this._origBill.asEnteredBolParties !== undefined) {
      const toDelete: Party[] = [];
      this._origBill.asEnteredBolParties.forEach((origParty) => {
        if (!_some(bill.asEnteredBolParties, (party) => origParty.partyTypeId === party.partyTypeId)) {
          toDelete.push(origParty);
        }
      });
      if (toDelete.length > 0) {
        toDelete.forEach((party) => {
          party.actionCd = ActionCd.DELETE;
          bill.asEnteredBolParties.push(party);
        });
      }
      bill.asEnteredBolParties.forEach((party) => {
        if (Party.empty(party) && party.actionCd !== ActionCd.ADD) {
          party.actionCd = ActionCd.DELETE;
        }
      });
      // SPEED-2278 - Backend will error out if the fields are empty even though its a delete
      const asEnteredBillTo = _find(
        bill.asEnteredBolParties,
        (party: Party) => party.partyTypeId === PARTY_TYPE_ID.BillTo
      );
      if (asEnteredBillTo && asEnteredBillTo.actionCd === ActionCd.DELETE && Party.empty(asEnteredBillTo)) {
        const origAsEnteredBillTo = _find(
          this._origBill.asEnteredBolParties,
          (party: Party) => party.partyTypeId === PARTY_TYPE_ID.BillTo
        );
        const copyFields = [
          'name1',
          'name2',
          'address',
          'city',
          'state',
          'zip',
          'zip4',
          'country',
          'customerNumber',
          'sicCd',
          'madCode',
        ];
        copyFields.forEach((field) => (asEnteredBillTo[field] = origAsEnteredBillTo[field]));
      }
    }
    return bill;
  }

  private reconcileAsMatchedPartiesDiff(bill: Bill): Bill {
    if (this._origBill !== undefined && this._origBill.asMatchedParties !== undefined) {
      const toDelete: Party[] = [];
      this._origBill.asMatchedParties.forEach((origParty) => {
        if (!_some(bill.asMatchedParties, (party) => origParty.partyTypeId === party.partyTypeId)) {
          toDelete.push(origParty);
        }
      });
      if (toDelete.length > 0) {
        toDelete.forEach((party) => {
          party.actionCd = ActionCd.DELETE;
          bill.asMatchedParties.push(party);
        });
      }
      bill.asMatchedParties.forEach((party) => {
        if (Party.empty(party) && party.actionCd !== ActionCd.ADD) {
          party.actionCd = ActionCd.DELETE;
        }
      });
    }
    return bill;
  }

  private reconcileSupplementalReferenceNumberDiff(bill: Bill): Bill {
    if (
      this._origBill !== undefined &&
      this._origBill.supplementalReferenceNumberCollection !== undefined &&
      this._origBill.supplementalReferenceNumberCollection.referenceNumbers.length
    ) {
      const toDelete: SupplementalReferenceNumber[] = [];
      this._origBill.supplementalReferenceNumberCollection.referenceNumbers
        .filter((item) => item.actionCd !== ActionCd.ADD)
        .forEach((origSrn) => {
          if (
            !_some(
              bill.supplementalReferenceNumberCollection.referenceNumbers,
              (srn) => origSrn.sequenceNbr === srn.sequenceNbr
            )
          ) {
            toDelete.push(origSrn);
          }
        });
      if (toDelete.length > 0) {
        toDelete.forEach((srn) => {
          srn.actionCd = ActionCd.DELETE;
          bill.supplementalReferenceNumberCollection.referenceNumbers.push(srn);
        });
      }
    }
    return bill;
  }

  private reconcileAccessorialsDiff(bill: Bill): Bill {
    if (this._origBill !== undefined && this._origBill.accessorials !== undefined) {
      const originalAccessorials = this._origBill.accessorials;
      const result = {};

      bill.accessorials.forEach((accessorial: Accessorial) => {
        result[accessorial.accessorialCd] = accessorial;
      });

      originalAccessorials
        .filter((accessorial: Accessorial) => accessorial.actionCd !== ActionCd.ADD)
        .filter(
          (accessorial: Accessorial) => !_some(bill.accessorials, (acc) => accessorial.sequenceNbr === acc.sequenceNbr)
        )
        .map((accessorial: Accessorial) => ({ ...accessorial, actionCd: ActionCd.DELETE }))
        .forEach((accessorial: Accessorial) => {
          if (result[accessorial.accessorialCd]) {
            result[accessorial.accessorialCd] = {
              ...result[accessorial.accessorialCd],
              actionCd: ActionCd.UPDATE,
              sequenceNbr: accessorial.sequenceNbr,
            };
          } else {
            result[accessorial.accessorialCd] = accessorial;
          }
        });

      bill.accessorials = Object.keys(result).map((key) => result[key]);
    }
    return bill;
  }

  private reconcileCommoditiesDiff(bill: Bill): Bill {
    if (
      this._origBill !== undefined &&
      this._origBill.orderDetails !== undefined &&
      this._origBill.orderDetails.commodities !== undefined
    ) {
      const toDelete: Commodity[] = [];
      this._origBill.orderDetails.commodities
        .filter((item) => item.actionCd !== ActionCd.ADD)
        .forEach((origCommodity) => {
          if (
            !_some(bill.orderDetails.commodities, (commodity) => origCommodity.sequenceNbr === commodity.sequenceNbr)
          ) {
            toDelete.push(origCommodity);
          }
        });
      if (toDelete.length > 0) {
        toDelete.forEach((commodity) => {
          commodity.actionCd = ActionCd.DELETE;
          bill.orderDetails.commodities.push(commodity);
        });
      }
    }

    if (!bill.orderDetails.commodities || bill.orderDetails.commodities.length === 0) {
      this.loggingApiService.error('Commoditites missing!  Displaying error to user.');
      if (this._origBill.orderDetails.commodities) {
        this.loggingApiService.info(
          `Original commodies payload: ${JSON.stringify(this._origBill.orderDetails.commodities)}`
        );
      }
      throw new Error('Commodity information missing, unable to continue processing.');
    }
    return bill;
  }

  reconcileAdditionalInfoDiff(rqst: UpsertShipmentRqst): UpsertShipmentRqst {
    // checking to see if Bill was not seeded or seeded from Bill Of Lading.  If so, all remarks are adds ( default )
    if (!this._origBill || !this._origBill.additionalInformation || !this._origBill.orderHeader.shipmentInstanceId) {
      return rqst;
    }

    // check carriers
    let newAdvanceCarrier;
    let newBeyondCarrier;

    if (rqst.advanceBeyondCarrier) {
      newAdvanceCarrier = rqst.advanceBeyondCarrier.find((item) => item.typeCd === AdvanceBeyondTypeCd.ADV_CARR);
      newBeyondCarrier = rqst.advanceBeyondCarrier.find((item) => item.typeCd === AdvanceBeyondTypeCd.BYD_CARR);
    }

    const beyondCarrier = BillEntryService.processCarrierActions(
      newBeyondCarrier,
      this._origBill.additionalInformation,
      AdvanceBeyondTypeCd.BYD_CARR
    );
    if (beyondCarrier) {
      rqst.advanceBeyondCarrier.push(beyondCarrier);
    }
    const advanceCarrier = BillEntryService.processCarrierActions(
      newAdvanceCarrier,
      this._origBill.additionalInformation,
      AdvanceBeyondTypeCd.ADV_CARR
    );
    if (advanceCarrier) {
      rqst.advanceBeyondCarrier.push(beyondCarrier);
    }

    const addItem = (item) => {
      if (item) {
        if (!rqst.miscLineItem) {
          rqst.miscLineItem = [];
        }
        rqst.miscLineItem.push(item);
      }
    };

    // check misc
    let newCodAmount;
    let newDriverAmount;
    let newPartCollectAmount;
    let newPartPrepaidAmount;

    if (rqst.miscLineItem) {
      newCodAmount = rqst.miscLineItem.find((item) => item.lineTypeCd === MiscLineItemCd.COD_AMT);
      newDriverAmount = rqst.miscLineItem.find((item) => item.lineTypeCd === MiscLineItemCd.CASH_PPD_LN);
      newPartCollectAmount = rqst.miscLineItem.find((item) => item.lineTypeCd === MiscLineItemCd.PART_COLL_LN);
      newPartPrepaidAmount = rqst.miscLineItem.find((item) => item.lineTypeCd === MiscLineItemCd.PART_PPD_LN);
    }

    addItem(
      BillEntryService.processMiscLineItemActions(
        newCodAmount,
        this._origBill.additionalInformation,
        MiscLineItemCd.COD_AMT
      )
    );
    addItem(
      BillEntryService.processMiscLineItemActions(
        newDriverAmount,
        this._origBill.additionalInformation,
        MiscLineItemCd.CASH_PPD_LN
      )
    );
    addItem(
      BillEntryService.processMiscLineItemActions(
        newPartPrepaidAmount,
        this._origBill.additionalInformation,
        MiscLineItemCd.PART_PPD_LN
      )
    );
    addItem(
      BillEntryService.processMiscLineItemActions(
        newPartCollectAmount,
        this._origBill.additionalInformation,
        MiscLineItemCd.PART_COLL_LN
      )
    );

    // check customs bond
    if (
      this._origBill.additionalInformation.inbondNumber &&
      this._origBill.additionalInformation.inbondNumber.trim().length > 0
    ) {
      if (rqst.customsBond && rqst.customsBond.length === 1) {
        if (
          rqst.customsBond[0].stateCd === this._origBill.additionalInformation.inbondStateCode &&
          rqst.customsBond[0].city === this._origBill.additionalInformation.inbondCity &&
          rqst.customsBond[0].bondNbr === this._origBill.additionalInformation.inbondNumber
        ) {
          rqst.customsBond[0].listActionCd = ActionCd.NO_ACTION;
        }
      } else {
        const customsBond = [];
        const aCustomsBond: CustomsBond = new CustomsBond();
        aCustomsBond.bondNbr = this._origBill.additionalInformation.inbondNumber;
        aCustomsBond.city = this._origBill.additionalInformation.inbondCity;
        aCustomsBond.stateCd = this._origBill.additionalInformation.inbondStateCode;
        aCustomsBond.sequenceNbr = `${this._origBill.additionalInformation.inbondSequenceNbr}`;
        aCustomsBond.listActionCd = ActionCd.DELETE;
        customsBond.push(aCustomsBond);
        rqst.customsBond = customsBond;
      }
    }

    // check blind shipper
    if (this._origBill.additionalInformation.isBlind) {
      const newBlindShipper = rqst.asEnteredBolParty
        ? rqst.asEnteredBolParty.find((item) => item.partyTypeCd === BolPartyTypeCd.BLIND_SHPR)
        : null;
      // check customs bond
      if (
        this._origBill.additionalInformation.shipperName &&
        this._origBill.additionalInformation.shipperName.trim().length > 0
      ) {
        if (newBlindShipper) {
          if (
            this._origBill.additionalInformation.shipperName === newBlindShipper.name1 &&
            this._origBill.additionalInformation.shipperAddress === newBlindShipper.address &&
            this._origBill.additionalInformation.shipperCity === newBlindShipper.city &&
            this._origBill.additionalInformation.shipperState === newBlindShipper.stateCd &&
            this._origBill.additionalInformation.shipperCountryCd === newBlindShipper.countryCd &&
            this._origBill.additionalInformation.shipperZip === newBlindShipper.zip6
          ) {
            newBlindShipper.listActionCd = ActionCd.NO_ACTION;
          }
        } else {
          const asEntered = new AsEnteredBolParty();
          asEntered.partyTypeCd = BolPartyTypeCd.BLIND_SHPR;
          asEntered.name1 = this._origBill.additionalInformation.shipperName;
          asEntered.address = this._origBill.additionalInformation.shipperAddress;
          asEntered.city = this._origBill.additionalInformation.shipperCity;
          asEntered.stateCd = this._origBill.additionalInformation.shipperState;
          asEntered.countryCd = this._origBill.additionalInformation.shipperCountryCd;
          asEntered.zip6 = this._origBill.additionalInformation.shipperZip;
          asEntered.listActionCd = ActionCd.DELETE;
          if (!rqst.asEnteredBolParty) {
            rqst.asEnteredBolParty = [];
          }
          rqst.asEnteredBolParty.push(asEntered);
        }
      }
    }

    // check true blind shipper
    if (this._origBill.additionalInformation.isTrueBlind) {
      const newTrueBlindShipper = rqst.asEnteredBolParty.find(
        (item) => item.partyTypeCd === BolPartyTypeCd.TRUE_BLIND_SHPR
      );
      // check customs bond
      if (
        this._origBill.additionalInformation.shipperName &&
        this._origBill.additionalInformation.shipperName.trim().length > 0
      ) {
        if (newTrueBlindShipper) {
          if (
            this._origBill.additionalInformation.shipperName === newTrueBlindShipper.name1 &&
            this._origBill.additionalInformation.shipperAddress === newTrueBlindShipper.address &&
            this._origBill.additionalInformation.shipperCity === newTrueBlindShipper.city &&
            this._origBill.additionalInformation.shipperState === newTrueBlindShipper.stateCd &&
            this._origBill.additionalInformation.shipperCountryCd === newTrueBlindShipper.countryCd &&
            this._origBill.additionalInformation.shipperZip === newTrueBlindShipper.zip6
          ) {
            newTrueBlindShipper.listActionCd = ActionCd.NO_ACTION;
          }
        } else {
          const asEntered = new AsEnteredBolParty();
          asEntered.partyTypeCd = BolPartyTypeCd.TRUE_BLIND_SHPR;
          asEntered.name1 = this._origBill.additionalInformation.shipperName;
          asEntered.address = this._origBill.additionalInformation.shipperAddress;
          asEntered.city = this._origBill.additionalInformation.shipperCity;
          asEntered.stateCd = this._origBill.additionalInformation.shipperState;
          asEntered.countryCd = this._origBill.additionalInformation.shipperCountryCd;
          asEntered.zip6 = this._origBill.additionalInformation.shipperZip;
          asEntered.listActionCd = ActionCd.DELETE;
          rqst.asEnteredBolParty.push(asEntered);
        }
      }
    }

    return rqst;
  }

  reconcileRemarksDiff(rqst: UpsertShipmentRqst): UpsertShipmentRqst {
    // checking to see if fetched Bill had existing remarks.  If not, all remarks are adds ( default )
    if (
      !this._origBill ||
      !this._origBill.remarks ||
      !this._origBill.orderHeader ||
      !this._origBill.orderHeader.shipmentInstanceId ||
      this._origBill.source === BillSource.BOL
    ) {
      return rqst;
    }

    // check add changes
    const newHazmatRemark = rqst.remark.find((item) => item.typeCd === ShipmentRemarkTypeCd.ADHOC_HZ_MATL_RMK);
    const newOperationalRemark = rqst.remark.find((item) => item.typeCd === ShipmentRemarkTypeCd.SHIPPING_RMK);
    const newOSDRemark = rqst.remark.find((item) => item.typeCd === ShipmentRemarkTypeCd.OSD_CNTCT);
    const newPieceCountRemark = rqst.remark.find((item) => item.typeCd === ShipmentRemarkTypeCd.PIECE_CNT_RMK);

    const hazMatRemark = BillEntryService.processRemarkActions(
      newHazmatRemark,
      this._origBill.remarks.hazmatEmergencyContactInfo,
      ShipmentRemarkTypeCd.ADHOC_HZ_MATL_RMK
    );
    if (hazMatRemark) {
      rqst.remark.push(hazMatRemark);
    }

    const operationsRemark = BillEntryService.processRemarkActions(
      newOperationalRemark,
      this._origBill.remarks.operationalRemarks,
      ShipmentRemarkTypeCd.SHIPPING_RMK
    );
    if (operationsRemark) {
      rqst.remark.push(operationsRemark);
    }

    const osdRemark = BillEntryService.processRemarkActions(
      newOSDRemark,
      this._origBill.remarks.osdContact,
      ShipmentRemarkTypeCd.OSD_CNTCT
    );
    if (osdRemark) {
      rqst.remark.push(osdRemark);
    }

    const pieceCountRemark = BillEntryService.processRemarkActions(
      newPieceCountRemark,
      this._origBill.remarks.pieceCount,
      ShipmentRemarkTypeCd.PIECE_CNT_RMK
    );
    if (pieceCountRemark) {
      rqst.remark.push(pieceCountRemark);
    }

    return rqst;
  }

  handleActionCdUpdate(currentActionCd: ActionCd): ActionCd {
    return Actionable.handleActionCdUpdate(
      currentActionCd,
      this._origBill && this._origBill.orderHeader ? this._origBill.orderHeader.shipmentInstanceId : 0
    );
  }

  /**
   * Returns true of both accessorials and commodities grids combined have more than the declared limit.
   *
   * @private
   * @param {number} limit
   * @returns {boolean}
   * @memberof BillEntryService
   */
  private hasExceedLineItemLimit(limit: number): boolean {
    const billFg = this.billFormGroupSubject.getValue();
    let numberOfAccessorials = 0;
    let numberOfCommodities = 0;

    if (billFg.get(BillFormFields.Accessorials) && billFg.get(BillFormFields.Accessorials).value) {
      billFg
        .get(BillFormFields.Accessorials)
        .value.forEach((v) => (Accessorial.hasValue(v) ? numberOfAccessorials++ : null));
    }

    if (
      billFg.get(BillFormFields.OrderDetails).get(OrderDetailsFormFields.Commodities) &&
      billFg.get(BillFormFields.OrderDetails).get(OrderDetailsFormFields.Commodities).value
    ) {
      billFg
        .get(BillFormFields.OrderDetails)
        .get(OrderDetailsFormFields.Commodities)
        .value.forEach((v) => (Commodity.hasValue(v) ? numberOfCommodities++ : null));
    }

    return numberOfAccessorials + numberOfCommodities > limit;
  }

  /**
   * Used in parties to determine if the SIC can be updated.
   * @returns {boolean}
   */
  hasInitialSic(party: PARTY_TYPE_ID): boolean {
    return (
      this._origBill &&
      this._origBill.asEnteredBolParties[party] &&
      !_isEmpty(this._origBill.asEnteredBolParties[party].sicCd)
    );
  }

  hasInitialProNumber(): boolean {
    return (
      this._origBill &&
      this._origBill.orderHeader &&
      this.formatValidationService.isValidProNumber(this._origBill.orderHeader.proNumber)
    );
  }

  /**
   * Used in parties to determine if the SIC can be updated.
   * @returns {boolean}
   */
  hasInitialPartyValue(party: PARTY_TYPE_ID, whichParty: BillFormFields, field: PartyFormFields): boolean {
    return (
      this._origBill &&
      this._origBill[whichParty][party] &&
      !_isNull(this._origBill[whichParty][party][field]) &&
      !_isEmpty(this._origBill[whichParty][party][field])
    );
  }

  getOriginalParty(party: PARTY_TYPE_ID, whichParty: BillFormFields): Party {
    if (this._origBill && this._origBill[whichParty]) {
      return this._origBill[whichParty][party] || new Party(party);
    }
    return new Party(party);
  }

  isHazmat() {
    const bill = this.billSubject.getValue();
    let hz = false;
    if (bill) {
      if (bill && bill.specialInstructions) {
        hz = bill.specialInstructions.hazmatInd;
      }
      if (!hz && bill.orderDetails && bill.orderDetails.commodities) {
        hz = bill.orderDetails.commodities.some((c) => c.hazmatInd);
      }
    }
    return hz;
  }

  isBilled() {
    const bill = this.billSubject.getValue();
    if (bill && bill.orderHeader) {
      return (
        bill.orderHeader.billStatusCode === BillStatusCd.BILLED ||
        bill.orderHeader.billStatusCode === BillStatusCd.RATED
      );
    }
    return false;
  }

  isReadOnly() {
    return this.isBilled();
  }

  /**
   * Returns true if pro number is the bypassProInputString
   *
   * @returns {boolean}
   * @memberof BillEntryService
   */
  isBypassProEntered(): boolean | undefined {
    if (this.bypassProString && this.bypassProString.trim().length) {
      const bill = this.billSubject.getValue();

      if (bill && bill.orderHeader) {
        return bill.orderHeader.proNumber.toUpperCase() === this.bypassProString.toUpperCase();
      }
    }
  }

  handleParentProValidation(
    pro: string,
    relatedPro: string,
    searchType: ParentShipmentValidationType
  ): Observable<boolean> {
    const observableResponse = new Subject<boolean>();
    if (
      this.formatValidationService.isValidProNumber(pro) &&
      this.formatValidationService.isValidProNumber(relatedPro)
    ) {
      if (pro === relatedPro) {
        observableResponse.error('Related PRO cannot equal PRO');
        observableResponse.complete();
      } else if (searchType) {
        this.shipmentService
          .validateParentShipment(pro, searchType, relatedPro, undefined, DataOptions.LoadingOverlayOnlyCall)
          .pipe(take(1))
          .subscribe(
            (response: ValidateParentChildShipmentResp) => {
              const billFormGroup = this.billFormGroupSubject.getValue();

              billFormGroup
                .get(BillFormFields.OrderHeader)
                .get(OrderHeaderFormFields.ParentInstanceId)
                .setValue(response.parentShipment.shipmentInstId);

              // IF THIS IS A MOVR WE ONLY UPDATE THE SHIPMENT INSTANCE ID
              if (searchType === ParentShipmentValidationType.MOVR) {
                observableResponse.next(true);
                observableResponse.complete();
                return;
              }
              this.snackBar.open({
                message: 'Bill updated from parent',
                matConfig:{
                  duration:3000,
                  verticalPosition: 'bottom',
                },
                status:'success'
              })
              billFormGroup
                .get(BillFormFields.OrderHeader)
                .get(OrderHeaderFormFields.PickupDate)
                .setValue(DateTimeHelper.conditionDateFromServer(new Date(response.parentShipment.pickupDate)));

              const asEntered = PartiesTransformer.fromAsEnteredBolParty(
                response.parentAsEnteredBolParty,
                response.parentShipment.originTerminalSicCd,
                response.parentShipment.destinationTerminalSicCd
              );

              const asMatched = PartiesTransformer.fromAsMatchedParty(
                response.parentAsMatchedParty,
                response.parentShipment.originTerminalSicCd,
                response.parentShipment.destinationTerminalSicCd
              );

              const mapParty = (partyTypeId: PARTY_TYPE_ID, partyGroup: FormGroup, newValues: Party) => {
                partyGroup.get(PartyFormFields.PartyTypeId).setValue(newValues.partyTypeId, { emitEvent: false });
                partyGroup.get(PartyFormFields.PartyTypeName).setValue(newValues.partyTypeName, { emitEvent: false });
                partyGroup.get(PartyFormFields.Name1).setValue(newValues.name1, { emitEvent: false });
                partyGroup.get(PartyFormFields.Name2).setValue(newValues.name2, { emitEvent: false });
                partyGroup.get(PartyFormFields.Address).setValue(newValues.address, { emitEvent: false });
                partyGroup.get(PartyFormFields.City).setValue(newValues.city, { emitEvent: false });
                partyGroup.get(PartyFormFields.State).setValue(newValues.state, { emitEvent: false });
                partyGroup.get(PartyFormFields.Zip).setValue(newValues.zip, { emitEvent: false });
                partyGroup.get(PartyFormFields.Zip4).setValue(newValues.zip4, { emitEvent: false });
                partyGroup
                  .get(PartyFormFields.Phone)
                  .setValue(this.xpoPhoneNumberPipe.transform(newValues.phone), { emitEvent: false });
                partyGroup.get(PartyFormFields.Extension).setValue(newValues.extension, { emitEvent: false });
                partyGroup.get(PartyFormFields.Country).setValue(newValues.country, { emitEvent: false });
                partyGroup.get(PartyFormFields.CustomerNumber).setValue(newValues.customerNumber, { emitEvent: false });
                partyGroup.get(PartyFormFields.SicCd).setValue(newValues.sicCd, { emitEvent: false });
                partyGroup
                  .get(PartyFormFields.MatchedStatusCd)
                  .setValue(newValues.matchedStatusCd, { emitEvent: false });
                partyGroup
                  .get(PartyFormFields.MatchedSourceCd)
                  .setValue(newValues.matchedSourceCd, { emitEvent: false });
                partyGroup.get(PartyFormFields.MadCd).setValue(newValues.madCode, { emitEvent: false });
                partyGroup.get(PartyFormFields.SequenceNbr).setValue(newValues.sequenceNbr, { emitEvent: false });
                partyGroup.get(PartyFormFields.ZipJoined).setValue(Party.getCombinedZipComponents(newValues), {
                  emitEvent: false,
                });

                partyGroup
                  .get(PartyFormFields.ActionCd)
                  .setValue(
                    partyGroup.get(PartyFormFields.ActionCd).value === ActionCd.NO_ACTION
                      ? ActionCd.UPDATE
                      : ActionCd.ADD,
                    { emitEvent: false }
                  );
              };

              const asEnteredParties = billFormGroup.get(BillFormFields.AsEnteredBolParties) as FormArray;
              const asMatchedParties = billFormGroup.get(BillFormFields.AsMatchedParties) as FormArray;

              mapParty(
                PARTY_TYPE_ID.Shipper,
                asEnteredParties.at(PARTY_TYPE_ID.Shipper) as FormGroup,
                asEntered[PARTY_TYPE_ID.Shipper]
              );

              mapParty(
                PARTY_TYPE_ID.Consignee,
                asEnteredParties.at(PARTY_TYPE_ID.Consignee) as FormGroup,
                asEntered[PARTY_TYPE_ID.Consignee]
              );

              mapParty(
                PARTY_TYPE_ID.Shipper,
                asMatchedParties.at(PARTY_TYPE_ID.Shipper) as FormGroup,
                asMatched[PARTY_TYPE_ID.Shipper]
              );

              mapParty(
                PARTY_TYPE_ID.Consignee,
                asMatchedParties.at(PARTY_TYPE_ID.Consignee) as FormGroup,
                asMatched[PARTY_TYPE_ID.Consignee]
              );
              asEnteredParties.updateValueAndValidity();
              asMatchedParties.updateValueAndValidity();

              const remarks = billFormGroup.get(BillFormFields.Remarks) as FormGroup;
              const updates = {};
              if (response.parentRemark) {
                response.parentRemark.forEach((remark) => {
                  switch (remark.typeCd) {
                    case ShipmentRemarkTypeCd.SHIPPING_RMK:
                      updates[RemarksFormFields.OperationalRemarks] = remark.remark;
                      break;
                    case ShipmentRemarkTypeCd.ADHOC_HZ_MATL_RMK:
                      updates[RemarksFormFields.HazmatEmergencyContactInfo] = remark.remark;
                      break;
                    case ShipmentRemarkTypeCd.OSD_CNTCT:
                      updates[RemarksFormFields.OsdContact] = remark.remark;
                      break;
                    case ShipmentRemarkTypeCd.PIECE_CNT_RMK:
                      updates[RemarksFormFields.PieceCount] = remark.remark;
                      break;
                  }
                });
              }
              remarks.patchValue(updates);

              const siUpdates = {
                [SpecialInstructionsFormFields.AfterHoursPickUp]:
                  response.parentShipment.lateTenderCd === LateTenderCd.LATE_TENDER,
                [SpecialInstructionsFormFields.ExcessiveValueInd]: response.parentShipment.excessiveValueInd,
                [SpecialInstructionsFormFields.CodInd]: response.parentShipment.codInd,
                [SpecialInstructionsFormFields.GuaranteedInd]: response.parentShipment.guaranteedInd,
                [SpecialInstructionsFormFields.FreezableInd]: response.parentShipment.freezableInd,
                [SpecialInstructionsFormFields.HazmatInd]:
                  response.parentShipment.hazmatInd || response.parentCommodity.some((item) => item.hazardousMtInd),
                [SpecialInstructionsFormFields.MexicoDoorToDoorInd]: response.parentShipment.mexicoDoorToDoorInd,
                [SpecialInstructionsFormFields.ExclusiveUseInd]: response.parentShipment.exclusiveUseInd,
                [SpecialInstructionsFormFields.RapidRemoteServiceInd]: response.parentShipment.serviceTypeCd === '3',
                [SpecialInstructionsFormFields.G12Ind]: response.parentShipment.serviceTypeCd === '4',
              };
              billFormGroup.get([BillFormFields.SpecialInstructions]).patchValue(siUpdates);

              const srnData = response.parentSuppRefNbr.map(SrnTransformer.suppRefNbrMapper).map((item) => {
                item.actionCd = ActionCd.ADD;
                return item;
              });
              if (srnData && srnData.length > 0) {
                this.addParentSupplementalRefNumSubject.next(srnData);
              }

              const uiTdc = TimeDateCriticalTransformer.toBill(
                response.parentTimeDateCritical,
                DateTimeHelper.conditionDateFromServer(new Date(response.parentShipment.estimatedDeliveryDate)),
                response.parentShipment
              );

              const tdcUpdate = {};

              if (uiTdc.dateTypeCode) {
                tdcUpdate[TimeDateCriticalFormFields.DateTypeCode] = uiTdc.dateTypeCode;
              }
              if (uiTdc.date1) {
                tdcUpdate[TimeDateCriticalFormFields.Date1] = uiTdc.date1;
              }
              if (uiTdc.date2) {
                tdcUpdate[TimeDateCriticalFormFields.Date2] = uiTdc.date2;
              }
              if (uiTdc.timeTypeCode) {
                tdcUpdate[TimeDateCriticalFormFields.TimeTypeCode] = uiTdc.timeTypeCode;
              }
              if (uiTdc.time1) {
                tdcUpdate[TimeDateCriticalFormFields.Time1] = uiTdc.time1;
              }
              if (uiTdc.time2) {
                tdcUpdate[TimeDateCriticalFormFields.Time2] = uiTdc.time2;
              }
              if (uiTdc.estimatedDeliveryDate) {
                tdcUpdate[TimeDateCriticalFormFields.EstimatedDeliveryDate] = uiTdc.estimatedDeliveryDate;
              }

              tdcUpdate[TimeDateCriticalFormFields.NotifyOnArrival] = uiTdc.notifyOnArrival;
              tdcUpdate[TimeDateCriticalFormFields.AppointmentRequired] = uiTdc.appointmentRequired;
              tdcUpdate[TimeDateCriticalFormFields.TDC] = uiTdc.tdc;

              billFormGroup.get([BillFormFields.TimeDateCritical]).patchValue(tdcUpdate);

              const additionalInformation = AdditionalInformationTransformer.parseMiscLineItems(
                response.parentMiscLineItem,
                new AdditionalInformation()
              );

              AdditionalInformationTransformer.parseBolAttachment(response.parentBolAttachment, additionalInformation);
              AdditionalInformationTransformer.parseCustomsBond(response.parentCustomsBond, additionalInformation);
              AdditionalInformationTransformer.parseOtherShipmentData(response.parentShipment, additionalInformation);
              AdditionalInformationTransformer.parseAdvanceBeyondCarrier(
                response.parentAdvanceBeyondCarrier,
                additionalInformation
              );

              const additionalInfoUpdate = {};
              additionalInfoUpdate[AdditionalInformationFormFields.DriverCash] = additionalInformation.driverCash;
              additionalInfoUpdate[AdditionalInformationFormFields.CheckNumber] = additionalInformation.checkNumber;
              additionalInfoUpdate[AdditionalInformationFormFields.CodAmount] = additionalInformation.codAmount;
              additionalInfoUpdate[AdditionalInformationFormFields.CodPaymentTypeCode] =
                additionalInformation.codPaymentTypeCode;
              additionalInfoUpdate[AdditionalInformationFormFields.PartPrepaidAmount] =
                additionalInformation.partPrepaidAmount;
              additionalInfoUpdate[AdditionalInformationFormFields.PartPrepaidRemarks] =
                additionalInformation.partPrepaidRemarks;
              additionalInfoUpdate[AdditionalInformationFormFields.PartCollectAmount] =
                additionalInformation.partCollectAmount;
              additionalInfoUpdate[AdditionalInformationFormFields.PartCollectRemarks] =
                additionalInformation.partCollectRemarks;

              additionalInfoUpdate[AdditionalInformationFormFields.HasCustomsInvoiceDocument] =
                additionalInformation.hasCustomsInvoiceDocument;
              additionalInfoUpdate[AdditionalInformationFormFields.HasPackingListDocument] =
                additionalInformation.hasPackingListDocument;
              additionalInfoUpdate[AdditionalInformationFormFields.HasSupplementalPagesDocument] =
                additionalInformation.hasSupplementalPagesDocument;
              additionalInfoUpdate[AdditionalInformationFormFields.HasCBPInbondDocument] =
                additionalInformation.hasCBPInbondDocument;

              additionalInfoUpdate[AdditionalInformationFormFields.InbondNumber] = additionalInformation.inbondNumber;
              additionalInfoUpdate[AdditionalInformationFormFields.InbondCity] = additionalInformation.inbondCity;
              additionalInfoUpdate[AdditionalInformationFormFields.InbondStateCode] =
                additionalInformation.inbondStateCode;

              additionalInfoUpdate[AdditionalInformationFormFields.LinealFoot] = additionalInformation.linealFoot;
              additionalInfoUpdate[AdditionalInformationFormFields.DeclaredValueAmount] =
                additionalInformation.declaredValueAmount;

              additionalInfoUpdate[AdditionalInformationFormFields.AdvancedCarrierSCAC] =
                additionalInformation.advancedCarrierSCAC;
              if (additionalInformation.advancedCarrierPickupDate) {
                additionalInfoUpdate[AdditionalInformationFormFields.AdvancedCarrierPickupDate] =
                  additionalInformation.advancedCarrierPickupDate;
              }
              additionalInfoUpdate[AdditionalInformationFormFields.AdvancedCarrierTrackingNumber] =
                additionalInformation.advancedCarrierTrackingNumber;

              additionalInfoUpdate[AdditionalInformationFormFields.BeyondCarrierSCAC] =
                additionalInformation.beyondCarrierSCAC;
              if (additionalInformation.beyondCarrierPickupDate) {
                additionalInfoUpdate[AdditionalInformationFormFields.BeyondCarrierPickupDate] =
                  additionalInformation.beyondCarrierPickupDate;
              }
              additionalInfoUpdate[AdditionalInformationFormFields.BeyondCarrierTrackingNumber] =
                additionalInformation.beyondCarrierTrackingNumber;

              billFormGroup.get([BillFormFields.AdditionalInformation]).patchValue(additionalInfoUpdate);

              // commodities is a bit difficult to add externally so we will broadcast the items to that component instead.
              this.commoditiesTransformerService
                .mapCommodity(response.parentCommodity, false, false)
                .subscribe((mapped) => {
                  if (mapped) {
                    this.addParentCommoditiesSubject.next(
                      mapped.map((item) => {
                        item.actionCd = ActionCd.ADD;
                        return item;
                      })
                    );
                  }
                });

              if (response.parentAccessorialService) {
                const accData = AccessorialTransformer.toAccessorial(response.parentAccessorialService).map((acc) => {
                  acc.actionCd = ActionCd.ADD;
                  return acc;
                });
                this.addParentAccessorialsSubject.next(accData);
              }

              observableResponse.next(true);
              observableResponse.complete();
            },
            (error) => {
              const message =
                _get(error, 'error.message') ||
                _get(error, 'error.error.message') ||
                'Unknown error while validating related PRO';

              observableResponse.error(message);
              observableResponse.complete();
            }
          );
      }
    }
    return observableResponse;
  }
    // Validaing the parties to set valid listActionCd accroding to sequenceNbr 
    // Jira:- LCS-17066
    validateSequenceNbr(parties){
      parties.forEach(ele =>{
        if (ele.sequenceNbr === '-1' && ele.listActionCd !== ActionCd.NO_ACTION){
          ele.listActionCd = ActionCd.ADD
        }
      })
      return parties;
    }

    // Creating APT accessorial to push in accessorialService array 
    // Jira:- LCS-17821
    createAptAccessorials(accessorial){
      let acc = accessorial;
      delete acc.auditInfo;
      acc.description = "APT APPOINTMENT";
      acc.accessorialUnitOfMeasure = "Shipment";
      acc.listActionCd = ActionCd.DELETE;
      return acc;
    }
}

/**
 * Not very pretty as this requires the property to be called "class", but it was affective.
 * @param key
 * @param value
 * @returns {any}
 * @constructor
 */
export function commodityClassCdReviver(key: any, value: any): any {
  return typeof value === 'string' && key === 'class' && !value.startsWith('Clss') && CommodityClassCd[`CLSS_${value}`]
    ? `Clss${value}`
    : value;
}
